'use client'

import { Fragment } from 'react';
import Link from 'next/link';
import { Popover, Transition } from '@headlessui/react';
import clsx from 'clsx';
import Image from 'next/image';

import { Button } from '@/components/Button';
import { Container } from '@/components/Container';
import { NavLink } from '@/components/NavLink';

import logo from '../../public/logos/Car Care Anywhere cl.png'; // Static import for the logo

function MobileNavIcon({ open }) {
  return (
    <svg
      aria-hidden="true"
      className="h-3.5 w-3.5 overflow-visible stroke-slate-700"
      fill="none"
      strokeWidth={2}
      strokeLinecap="round"
    >
      <path
        d="M0 1H14M0 7H14M0 13H14"
        className={clsx('origin-center transition', open && 'scale-90 opacity-0')}
      />
      <path
        d="M2 2L12 12M12 2L2 12"
        className={clsx('origin-center transition', !open && 'scale-90 opacity-0')}
      />
    </svg>
  );
}

function MobileNavigation() {
  return (
    <Popover>
      <Popover.Button
        className="relative z-10 flex h-8 w-8 items-center justify-center outline-none"
        aria-label="Toggle Navigation"
      >
        {({ open }) => <MobileNavIcon open={open} />}
      </Popover.Button>
      <Transition.Root>
        <Transition.Child
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="duration-150 ease-in"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Popover.Overlay className="fixed inset-0 bg-slate-300/50" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            className="absolute inset-x-0 top-full mt-4 origin-top flex-col rounded-2xl bg-white p-4 text-lg tracking-tight text-slate-900 shadow-xl ring-1 ring-slate-900/5"
          >
            <NavLink href="#pricing">Services</NavLink>
            <NavLink href="#about-us">About Us</NavLink>
            <NavLink href="#faq">FAQs</NavLink>
            {/* <NavLink href="#givingback">Giving Back</NavLink> */}
          </Popover.Panel>
        </Transition.Child>
      </Transition.Root>
    </Popover>
  );
}

export function Header() {
  const scrollToTop = (e) => {
    e.preventDefault();
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <header className="text-primary py-4 fixed top-0 left-0 right-0 z-50 bg-secondary shadow-md">
      <Container>
        <div className="flex justify-between items-center">
          <Link href="/" aria-label="Home" onClick={scrollToTop}>
            <Image
              src={logo}
              alt="Car Care Anywhere Logo"
              width={150}
              height={50}
              className="h-16 w-auto max-w-xs" // Adjust the width and height as needed
            />
          </Link>
          <nav className="hidden md:flex md:gap-x-6">
            <NavLink href="#pricing" className="text-white">Services</NavLink>
            <NavLink href="#about-us" className="text-white">About Us</NavLink>
            <NavLink href="#faq" className="text-white">FAQs</NavLink>
            {/* <NavLink href="#givingback" className="text-white">Giving Back</NavLink> */}
          </nav>
          <div className="flex items-center gap-x-5 md:gap-x-8">
            <Button
              href="/schedule"
              color="accent"
            >
              <span>Schedule Now <span className="hidden lg:inline"></span></span>
            </Button>
            <div className="-mr-1 md:hidden">
              <MobileNavigation />
            </div>
          </div>
        </div>
      </Container>
    </header>
  );
}

