'use client';

export function NavLink({ href, children }) {
  const handleClick = event => {
    if (href.startsWith("#")) {
      event.preventDefault();
      const id = href.substring(1);
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    } else {
      // Navigate using traditional methods if not an anchor link
      if (typeof window !== 'undefined') {
        window.location.href = href;
      }
    }
  };

  return (
    <a href={href} onClick={handleClick} className="inline-block rounded-lg px-2 py-1 text-sm text-slate-700 hover:bg-slate-100 hover:text-slate-900">
      {children}
    </a>
  );
}