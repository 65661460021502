'use client';

import Image from 'next/image';
import logo from '../../public/logos/Car Care Anywhere cl.png'; // Static import for the logo

export function ScrollableLogo() {
  const scrollToTop = (e) => {
    e.preventDefault();
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <a href="/" aria-label="Home" onClick={scrollToTop}>
      <Image
        src={logo}
        alt="Car Care Anywhere Logo"
        width={200}
        height={50}
        className="h-16 w-auto max-w-xs"
      />
    </a>
  );
}
