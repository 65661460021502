import(/* webpackMode: "eager" */ "/workspace/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/workspace/public/images/family.png");
;
import(/* webpackMode: "eager", webpackExports: ["Header"] */ "/workspace/src/components/Header.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["NavLink"] */ "/workspace/src/components/NavLink.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["ScrollableLogo"] */ "/workspace/src/components/ScrollableLogo.jsx");
